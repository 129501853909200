import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import TopHeroComponent from "../components/herocomponents/topherocomponent"
import Component1 from "../components/bodycomponents/component-one"
import Component2 from "../components/bodycomponents/component-two"
import ServicesFAQ from "../components/faq-components/servicesfaq"
import { serviceFaqItems } from "../utils/data/faq"
import R_BLUE_BG from "../images/background-images/bg-royal-blue.svg"
import GRAY_BG from "../images/background-images/bg-gray.svg"
import { colors } from "../utils/colors"

import sell_img from "../images/content/our-services/SSLLC-Services_-Sell-Equipment-800x534.jpeg"
import buy_img from "../images/content/our-services/SSLLC-Interior-Lab-Drone_Cropped-800x550.png"
import auctions_img from "../images/content/our-services/SSLLC-Services-Auctions-600x400.jpeg"
import lab_relocation_img from "../images/content/our-services/SSLLC-Services-Laboratory-Relocation-1.jpeg"
import logistic_img from "../images/content/our-services/SSLLC-Services-Logistics-e1729521298507-600x444.jpeg"
import appraisal_img from "../images/content/our-services/SSLLC-Appraisal-Services-1-800x534.jpg"

const OurServices = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Comprehensive Appraisal, Equipment & Relocation Services`}
          description={`Surplus Solutions provides a variety of services including appraisal, equipment, logistic, and relocation. Reach out to discuss your used equipment needs!`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          fontShadowColor={colors.royalBlue}
          shadowColor="shadow-royalBlue-bottom"
          pageTitle="Our Services"
          title="Comprehensive Lab Equipment Management Services"
          body={
            <p className="pt-4">
              Surplus Solutions is your trusted partner for complete Equipment
              Lifecycle Management.
            </p>
          }
        />

        <Component1
          title="Selling your Idle Equipment"
          body={
            <p>
              <b>
                Surplus Solutions will sell your surplus lab, processing or
                packing equipment on your behalf, for a single item or your
                entire inventory.{" "}
              </b>
              As a trusted leader, Surplus Solutions offers a range of options
              to sell your equipment—including brokerage, consignment, outright
              purchase, and online or onsite auctions—that we tailor to your
              exact needs. Plus, our experts manage every step from appraisal to
              storage to logistics, so you can save time and maximize returns.
              We handle the heavy lifting while you focus on your business.{" "}
            </p>
          }
          buttonLink="/sell-equipment"
          buttonTitle="Sell Your Lab Equipment"
          imageSource={sell_img}
          imageAlt="Modern laboratory interior out of focus, including equipment."
        />
        <Component2
          title="Buy Pre-Owned Equipment"
          body={
            <p>
              <b>
                The easy way to source and purchase lab equipment, whether
                you’re expanding, upgrading, or setting up a lab.{" "}
              </b>
              Surplus Solutions’ extensive inventory includes used top-quality
              equipment—from{" "}
              <Link to="/manufacturer/Thermo-Fisher-Scientific">
                Thermo Fisher Scientific
              </Link>
              ,{" "}
              <Link to="/manufacturer/Beckman-Coulter">
                Beckman Coulter
              </Link>
              , <Link to="/manufacturer/Sartorius">Sartorius</Link>
              , <Link to="/manufacturer/Eppendorf">Eppendorf</Link>
              ,{" "}
              <Link to="/manufacturer/Agilent-Technologies">
                Agilent
              </Link>
              , <Link to="/manufacturer/Waters">Waters</Link>, and
              many other leading{" "}
              <Link to="/buy-equipment/manufacturers/">
                manufacturers
              </Link>
              —at affordable prices. We thoroughly inspect each and every piece
              to ensure the highest quality and superb condition that meets your
              needs.
            </p>
          }
          buttonLink="/buy-equipment"
          buttonTitle="Browse Our Collection"
          imageSource={buy_img}
          imageAlt="Interior drone shot of Surplus Solutions facility of lab equipment."
        />
        <Component1
          title="Auctions"
          body={
            <p>
              <b>
                A proprietary and powerful online auction platform for selling
                and buying used laboratory, packaging, and processing equipment.{" "}
              </b>
              Surplus Solutions’ auctions let sellers reach a global audience
              and gives buyers access to top-notch new and used equipment at
              competitive prices. This means you can expect quick and seamless
              sales of your equipment, and as a buyer, you have access to a wide
              variety of top-notch brands and choices. To help you maximize the
              value of your transactions, we also offer pre-auction planning and
              consulting services.
            </p>
          }
          buttonLink="/auctions"
          buttonTitle="See Upcoming Auctions"
          imageSource={auctions_img}
          imageAlt="Auction gavel laying on a laptop on a wooden desk."
        />
        <Component2
          title="Laboratory Relocation Services"
          body={
            <p>
              <b>
                Up to the challenge of relocating a self-contained lab or an
                entire facility.{" "}
              </b>
              Surplus Solutions offers all-encompassing laboratory relocation
              services, from planning to transportation to set up, so you can
              count on a safe and seamless move. Our team makes it easy,
              managing your transition from start to finish and handling all the
              details with care and precision.
            </p>
          }
          buttonLink="/our-services/relocation-services"
          buttonTitle="Learn More About Relocation Services"
          imageSource={lab_relocation_img}
          imageAlt="External image of surplus equipment distribution warehouse with trucks of different capacity."
        />
        <Component1
          title="Logistic Services"
          body={
            <p>
              <b>
                Experienced and secure handling and transportation to ensure
                on-time arrival of valuable equipment.{" "}
              </b>
              Over the past 25 years, Surplus Solutions has built an extensive
              network of trusted logistics partners to make certain your
              equipment is in safe hands. We provide customized logistics
              services for shipments of any size, including custom crating,
              skidding, and rigging.
            </p>
          }
          buttonLink="/our-services/logistics-services"
          buttonTitle="Discover our Logistics Solutions"
          imageSource={logistic_img}
          imageAlt="Warehouse staff moving lab equipment with hand lift while walking through warehouse."
        />
        <Component2
          title="Appraisal Services"
          body={
            <p>
              <b>
                Fast and precise equipment appraisal services tailored to your
                unique needs.{" "}
              </b>
              Whether you need to appraise a single piece or an entire facility,
              our AMEA-certified experts provide detailed, accurate reports that
              comply with the latest industry standards. With Surplus Solutions’
              expertise, you’ll save valuable time while determining the true
              value of your surplus assets.
            </p>
          }
          buttonLink="/our-services/appraisal-services"
          buttonTitle="Explore Appraisal Services"
          imageSource={appraisal_img}
          imageAlt="Young happy female worker in a lab writing notes about appraisal of equipment."
        />

        <ServicesFAQ
          title="FAQ"
          items={serviceFaqItems}
          backgroundColor={colors.lightGray}
          backgroundImage={GRAY_BG}
          shadowColor="shadow-gray-top"
        />
      </Layout>
    </>
  )
}

export default OurServices
