import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

export default function ServicesFAQ(props) {
  // State to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null)

  // Toggle function to open/close an accordion item
  const toggleAccordion = (index) => {
    // If clicking the same index, close it; otherwise, open the new index
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div
      className="shadow-custom-out bg-cover"
      style={{
        backgroundColor: props.backgroundColor,
        backgroundImage: `URL(${props.backgroundImage})`,
      }}
    >
      <div className="container max-w-screen-xl w-full pt-14">
        <div className={`bg-white w-full rounded-t-[15px] mt-[15px] p-8 lg:p-10 ${props.shadowColor}`}>
          <h2 className="mb-4 text-left">{props.title}</h2>
          <div>
            {props.items.map((item, index) => (
              <div key={index} className="border-b border-b-light-gray">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full text-left px-2 py-4 focus:outline-none group"
                >
                  <div
                    className={`flex items-center font-semibold text-[1.4rem] ${
                      openIndex === index ? "text-red" : "group-hover:text-blue"
                    }`}
                  >
                    <span
                      className={`mr-4 flex items-center justify-center text-white min-w-6 min-h-6 ${
                        openIndex === index
                          ? "bg-red"
                          : "bg-gray group-hover:bg-blue"
                      }`}
                    >
                      {openIndex === index ? (
                        <FontAwesomeIcon icon={faMinus} size="sm"/>
                      ) : (
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      )}{" "}
                    </span>
                    <span
                      className={`${
                        openIndex === index
                          ? "text-red"
                          : "group-hover:text-blue"
                      }`}
                    >
                      {item.question}
                    </span>
                  </div>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-200 ease-in-out ${
                    openIndex === index ? "pt-2 pb-4" : "max-h-0"
                  }`}
                >
                  <div className="px-2 text-left">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-medium-gray pt-8 px-2">
            <h4>
              Have a different question? Check out our{" "}
              <Link to="/faq" className="linkBlue leading-[1.2rem]">
                FAQs
              </Link>{" "}
              or{" "}
              <Link to="/contact-us" className="linkBlue leading-[1.2rem]">
                reach out to us
              </Link>
              .
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}
