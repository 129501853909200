import React from "react"
import { Link } from "gatsby"

export default function Component2(props) {
  return (
    <div className="bg-white shadow-custom-in">
      <div className="container max-w-screen-xl flex flex-col md:flex-row-reverse justify-between py-14 gap-5 md:gap-10">
        <div className="flex w-full md:w-1/3">
          <img
            src={props.imageSource}
            alt={props.imageAlt}
            className="img-fluid rounded-[5px] cover max-h-[280px] w-full"
          />
        </div>

        <div className="w-full md:w-2/3">
          <h3>{props.title}</h3>
          <span>{props.body}</span>
          <div className="mt-4">
            <Link
              to={`${props.buttonLink}`}
              type="button"
              className="inline-block w-auto font-normal bg-red hover:bg-scarlet-red py-2 px-4 text-white hover:text-white text-[1.2rem] rounded-[4px]"
            >
              {props.buttonTitle}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
